.footer {
  &__inner {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    padding-top: 46px;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 12px;
      color: #fff;
      text-align: center;
      justify-content: unset;
      line-height: 1.2;
      opacity: 0.4;
      text-transform: uppercase;
      letter-spacing: 0.01px;

 
    }

    @media (max-width: 980px) {
      flex-direction: column;
      align-items: stretch;
      padding-bottom: 40px;
    }

    div {

        @media (max-width: 980px) {
            align-self: center;
          }

          &:first-child {

            @media (max-width: 980px) {
              margin-bottom: 24px;
              order: 2;
            }
        
          }

          &:nth-child(2) {
            @media (max-width: 980px) {
              order: 3;
            }
     
          }


      &:last-child {
        display: flex;
        align-items: center;
        align-self: stretch;
        justify-content: flex-end;

        @media (max-width: 980px) {
          justify-content: space-between;
          margin-bottom: 24px;
          order: 1;
        }

        a {
          @extend %description-18-book;
          color: $white;
          margin-right: 40px;

          @media (max-width: 980px) {
            margin-right: 24px;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        img {
          width: 32px;
        }
      }
    }
  }
}
