@font-face {
  font-family: 'Circular Std';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}






%tittle-72 {
  font-size: 72px;
  font-weight: 500;

  @media(max-width: 980px) {
    font-size: 40px;
  }
}

%title-58 {
  font-size: 58px;
}

%tittle-56 {
  font-size: 56px;
  font-weight: 500;
  @media(max-width: 980px) {
    font-size: 32px;
  }
}

%tittle-48 {
  font-size: 48px;
  font-weight: 500;
  @media(max-width: 980px) {
    font-size: 32px;
  }
}

%sub-title-32 {
  font-size: 32px;
  font-weight: 500;
}

%sub-title-24 {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Circular Std';

}

%description-20 {
  font-size: 20px;
  font-family: 'Circular Std';


}

%description-20-regular {
  font-size: 20px;
  font-weight: 400;
  font-family: 'Circular Std';
  @media(max-width: 980px) {
    font-size: 18px;
  }
}

%description-18-medium {
  font-size: 18px;
  font-weight: 500;
}

%description-18-book {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Circular Std';
}

%description-16-medium {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Circular Std';
}

%description-16-book {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Circular Std';
}

%description-14-bold {
  font-size: 14px;
  font-weight: 700;
}
%description-13-mono {
  font-family: 'Space Mono', monospace;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1.3px;
}

%description-12 {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: .5px;
}
