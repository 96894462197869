.card {
  background: $white;
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.02))
    drop-shadow(0px 2px 24px rgba(51, 34, 51, 0.04))
    drop-shadow(0px 1px 4px rgba(50, 54, 46, 0.08));
  width: 100%;
  border-radius: 24px;
  // overflow: hidden;

  &__header {
    // background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;

    &__text {
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
        width: 44px;
      }

      p {
        color: $base-dark;
        @extend %description-18-medium;
        line-height: 1.2;
      }

      span {
        @extend %description-13-mono;
        color: $dark-grey;
      }
    }

    &__info {
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      cursor: pointer;

      img {
        width: 18px;
      }
    }
  }

  &__body {
    width: inherit;
    height: inherit;
    position: relative;

    &__text {
      background: $base-dark;
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 14px;
      border-radius: 30px;

      p {
        @extend %description-13-mono;
        white-space: nowrap;
        z-index: 10;
      }
    }

    figure {
      width: inherit;
      height: 415px;
      overflow: hidden;
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
      opacity: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px; 
      }
    }
  }
}

.card-stats {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  &__stat {
    background: white;
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.02))
      drop-shadow(0px 2px 24px rgba(51, 34, 51, 0.04))
      drop-shadow(0px 1px 4px rgba(50, 54, 46, 0.08));

    h2 {
      @extend %description-20;
      display: flex;
      align-items: center;

      img {
        width: 18px;
        margin-right: 8px;

      }
    }

    p {
      @extend %description-12;
      margin-top: 8px;
      color: $dark-grey;

      @media (max-width: 980px) {
        font-size: 11px;
      }
    }
  }
}

.tba {
    color: $white;
  @extend %description-18-medium;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: $base-dark;
}
