.home {
  padding-top: 70px;
  overflow-x: hidden;
  @media (max-width: 640px) {
    padding-top: 40px;
  }
}

.hero {
  transform: translateY(-15%);
  opacity: 0;
  will-change: transform, opacity;


  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;

    @media (max-width: 980px) {
      grid-template-columns: 1fr;

    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    @media (max-width: 980px) {
      align-items: center;
      text-align: center;
    }

    @media (max-width: 640px) {
      align-items: flex-start;
      text-align: left;
    }

    h1 {
      @extend %tittle-72;
      display: flex;
      align-items: center;
      line-height: 1.2;

      sub {
        align-self: flex-end;
      }
      span {
        margin: 0 20px;
        background: rgba(103, 206, 103, 0.1);
        width: 70px;
        height: 50px;
        display: flex;
        border-radius: 32px;
        align-items: center;
        justify-content: center;

        @media (max-width: 980px) {
          margin: 0 10px;
        }

        img {
          width: 28px;
          height: 32px;
        }
      }
    }

    p {
      color: $dark-grey;
      @extend %description-20-regular;
      margin-top: 42px;
      line-height: 1.2;

      @media (max-width: 640px) {
        margin-top: 24px;
      }
    }
  }

  &__card {
    width: 380px;
    justify-self: flex-end;
    transform: translateY(-5%);
    opacity: 0;

    @media (max-width: 980px) {
      justify-self: center;
      // width: 95%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__socials {
    margin-top: 36px;

    @media (max-width: 640px) {
      display: none;
    }

    h4 {
      @extend %description-20-regular;
      color: $dark-grey;
    }

    div {
      margin-top: 24px;
    }

    img {
      width: 32px;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

.about {

  transform: translateY(20%);
  opacity: 0;
  will-change: transform, opacity;

  padding-top: 120px;
  padding-bottom: 200px;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 980px) {
    padding-top: 130px;
  }

  .mobile-heading {
    display: none;
    text-align: center;

    h2 {
      @extend %tittle-56;
      margin-bottom: 24px;
    }

    p {
      @extend %description-20-regular;
      line-height: 1.3;
      color: $dark-grey;
    }

    @media (max-width: 980px) {
      display: block;
    }

    @media (max-width: 640px) {
      text-align: left;
    }
  }

  .card {
    position: relative;
    z-index: 10;
  }

  hr {
    opacity: 0.3;
    @media (max-width: 980px) {
      display: none;
    }
  }

  &__inner {
    padding-top: 100px;
    display: grid;
    grid-template-columns: 0.7fr 1fr;
    grid-gap: 20px;
    align-items: center;

    @media (max-width: 980px) {
      padding-top: 24px;
      grid-template-columns: 1fr;
    }
  }

  &__content {
    width: 680px;
    justify-self: flex-end;
    overflow: visible;

    @media (max-width: 1240px) {
      width: 640px;
    }

    @media (max-width: 1180px) {
      width: 540px;
    }

    @media (max-width: 980px) {
      width: 100%;
      overflow: hidden;
    }

    h2 {
      @extend %tittle-56;
      margin-bottom: 24px;

      @media (max-width: 980px) {
        display: none;
      }
    }

    p {
      @extend %description-20-regular;
      line-height: 1.3;
      width: 85%;
      color: $dark-grey;
      @media (max-width: 980px) {
        display: none;
      }
    }

    .slide-card {
      padding: 20px;
      position: relative;

      span {
        position: absolute;
        right: 16px;
        top: 16px;
      }

      img {
        margin-bottom: 22px;
      }

      h3 {
        @extend %description-20;
        margin-bottom: 8px;
      }

      p {
        @extend %description-16-book;
        @media (max-width: 980px) {
          display: block;
        }
      }
    }

    .swiper {
      overflow: visible;
    }

    .swiper-slide {
      background: $white;
      width: 100%;
      width: 280px;

      opacity: 0.5;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-slide.swiper-slide-active {
      opacity: 1;
    }

    .swiper-slide.swiper-slide-duplicate.swiper-slide-prev {
      opacity: 0;
    }

    &.swiper-slide-active {
      &.arrow.arrow-prev {
        opacity: 0;
      }
    }

    .slider-nav {
      display: flex;
      align-items: center;
      margin-top: 24px;

      @media (max-width: 980px) {
        justify-content: flex-end;
      }

      .arrow {
        margin-right: 20px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          width: 17px;
        }
      }

      .arrow-prev {
        &.swiper-button-disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        img {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__slider {
    margin-top: 48px;
    width: 131%;
    overflow: hidden;

    @media (max-width: 1640px) {
      width: 120%;
    }
    @media (max-width: 1490px) {
      width: 100%;
    }

    @media (max-width: 980px) {
      margin-top: 0px;
      width: 100%;
    }
  }

  &__card {
    width: 380px;
    @media (max-width: 980px) {
      width: 380px;
      margin: 0 auto;
    }

    @media (max-width: 630px) {
      width: 100%;
      margin: 0 auto;
    }
  }
}

.scroll-active {
  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dark-section:focus {
  pointer-events: all;
  outline: none;
}

.dark-section {
  z-index: 11;
  height: 94vh;
  overflow: hidden;
  // overflow-y: auto;
  background: $base-dark;
  margin: 0 25px;
  border-radius: 40px;
  z-index: 2;
  position: relative;
  margin-bottom: 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 980px) {
    margin: 0 4px;
    border-radius: 12px;
    margin-bottom: 12px;
    height: auto;
  }
}

.benefits {
  padding-top: 140px;

  @media (max-width: 980px) {
    padding-top: 30px;
  }

  &__title {
    width: 65%;
    margin-bottom: 56px;

    @media (max-width: 980px) {
      width: 95%;
      margin-bottom: 50px;
    }

    h2 {
      @extend %tittle-56;
      color: $white;
      line-height: 1.2;
    }
    p {
      color: $base-green;
      font-size: 13px;
      margin-bottom: 30px;
      letter-spacing: 2px;

      @media (max-width: 980px) {
        font-size: 11px;
      }
    }
  }

  &__cards {
    &__row {
      &:nth-child(1) {
        display: grid;
        grid-template-columns: 0.6fr 0.4fr;
        grid-gap: 20px;

        @media (max-width: 980px) {
          grid-template-columns: 1fr;
          margin-bottom: 20px;
        }
      }

      &:nth-child(2) {
        display: grid;
        grid-template-columns: 0.7fr 0.3fr;
        grid-gap: 20px;

        @media (max-width: 980px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

.benefit-card {
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 20px;

  @media (max-width: 980px) {
    margin-bottom: 0px;
  }

  img {
    margin-bottom: 50px;
  }

  h4 {
    @extend %sub-title-24;
    margin-bottom: 20px;
  }

  p {
    @extend %description-18-book;
    width: 95%;
    line-height: 1.3;
  }
}

.green-bg {
  background: #26412c;
  color: $white;

  p {
    opacity: 0.6;
  }

  img {
    height: 48px;
  }
}

.pink-bg {
  background: #ffba88;

  color: $base-dark;
  p {
    opacity: 0.6;
  }
  img {
    height: 44px;
  }
}

.yellow-bg {
  background: #edc94c;
  color: $base-dark;
  img {
    height: 58px;
  }

  p {
    opacity: 0.6;
  }
}

.light-green-bg {
  position: relative;
  background: #a1c599;
  color: $white;
  overflow: hidden;

  h5 {
    @extend %description-14-bold;
    position: relative;
    z-index: 1;
    // margin-top: 60px;
    margin-bottom: 20px;
  }

  h2 {
    @extend %title-58;
    position: relative;
    z-index: 1;
    margin-bottom: 28px;
  }

  p {
    position: relative;
    z-index: 1;
    opacity: 0.8;
  }
  img {
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    width: 100%;
    z-index: 0;
    max-width: 100%;
  }
}

.roadmap {
  margin-top: 200px;
  margin-bottom: 240px;

  @media (max-width: 980px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media (max-width: 640px) {
    .wrapper {
      max-width: 100%;
    }
  }
  &__slider {
    width: 100%;

    // @media (max-width: 980px) {
    //   overflow: visible !important;
    // }
  }

  &__inner {
    h2 {
      @extend %tittle-56;
      color: $white;
      // margin-left: 18%;

      @media (max-width: 640px) {
        margin-left: 5%;
      }
    }
  }

  &__cards {
    margin-top: 56px;
    // padding: 0 18%;

    // @media(max-width: 640px) {
    //   padding: 0;
    // }
  }

  &__card {
    background: #131313;
    padding: 40px;
    border-radius: 16px;
    min-height: 448px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    position: relative;

    @media (max-width: 980px) {
      padding: 30px 24px;
    }

    h1 {
      color: $white;
      font-size: 40px;
      font-weight: 400;
      @media (max-width: 980px) {
        font-size: 32px;
      }
    }

    &__item {
      color: $white;

      div {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: block;
          width: 20px;
          height: 20px;
          background: transparent;
          border-radius: 50%;
          border: 2px solid $dark-grey;
          margin-right: 10px;
          flex: 0 0 20px;
        }

        p {
          @extend %description-18-book;
        }
      }
    }

    &.past {
      border: 1px solid $white;
      // opacity: .5;
    }
  }
}


.faq {
  color: $white;

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 980px) {
      grid-template-columns: 1fr;
    }
  }

  &__column {
    h2 {
      @extend %tittle-48;
      line-height: 1.2;
    }
  }

  .acordion {
    &__item {
      background: #131313;
      margin-bottom: 10px;
      border-radius: 16px;
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 64px;
      padding: 0 20px;
      cursor: pointer;

      @media (max-width: 980px) {
        min-height: 74px;
      }

      img {
        height: 18px;
      }
      h4 {
        @extend %description-20;
        @media (max-width: 980px) {
          width: 75%;
          line-height: 1.2;
        }
      }
    }

    &__content {
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-out;
      padding: 0 20px;

      p {
        @extend %description-18-book;
        line-height: 1.3;
        padding-bottom: 20px;
        opacity: 0.6;
      }

      &.active-acordion {
        max-height: 9999px;
        overflow: auto;
        overflow-y: hidden;
        transition: all 1.5s ease;
      }
    }
  }
}
.arrow-active {
  transform: rotate(180deg);
}

.plantoids {
  margin-top: 215px;

  @media (max-width: 980px) {
    margin-top: 50px;
  }

  &__red-bg {
    background: rgba(206, 103, 103, 0.1);
  }

  &__green-bg {
    background: rgba(103, 206, 103, 0.1);
  }

  &__inner {
    color: $white;

    h2 {
      @extend %tittle-56;
      margin-bottom: 36px;
    }

    p {
      @extend %description-20;
      line-height: 1.3;
      opacity: 0.6;
    }
  }

  &__cards {
    margin-top: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;

    @media (max-width: 980px) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    background: #131313;
    padding: 32px;
    border-radius: 16px;

    h3 {
      @extend %sub-title-24;
      margin-bottom: 18px;
    }

    p {
      @extend %description-18-book;
      line-height: 1.3;
    }

    a {
      display: flex;
      align-items: center;
      margin-top: 23px;

      img {
        margin-right: 13px;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 24px;
      width: 70px;
      border-radius: 36px;
      margin-bottom: 32px;

      img {
        width: 20px;
      }
    }
  }
}

.partners {
  margin-top: 60px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    @extend %description-18-book;
    margin-bottom: 20px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    margin-right: 24px;
    mix-blend-mode: luminosity;

    &:last-child {
      margin-right: 24px;
    }
  }
}

.checked-text {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 10px;
  }
  p {
    color: #67ce67;
    text-decoration: line-through;
  }
}

.swiper-slide:nth-child(1) {
  .roadmap__card {
    // h1 {
    //   opacity: 0.3;
    // }
    &:after {
      display: block;
      font-family: inherit;
      position: absolute;
      left: 45px;
      top: 90px;
      color: $base-green;
      content: "We're here";
    }
  }
}

// .swiper-slide:nth-child(2) {
//   .roadmap__card {
//     // h1 {
//     //   opacity: 0.3;
//     // }
//     background: none;
//     border: 1px solid rgba(255, 255, 255, 0.2);
//     opacity: 0.4;
//   }
// }

.swiper-slide:nth-child(n) {
  .roadmap__card {
    &:after {
      display: block;
      font-family: inherit;
      position: absolute;
      left: 45px;
      top: 90px;
      color: $base-green;

      @media (max-width: 1160px) {
        right: auto;
        left: 40px;
        top: 90px;
      }

      @media (max-width: 980px) {
        right: 40px;
        top: 40px;
        left: auto;
      }
    }
  }
}

// .roadmap__slider .swiper-slide:first-child .roadmap__card {
//   background: none;
//   border: 1px solid rgba(255, 255, 255, 0.2);
//   opacity: 0.4;
// }

.pdfWrapper {
  width: 100%;
  height: 100%;
}

.pdfWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
