@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import url('https://fonts.cdnfonts.com/css/circular-std?styles=17909,17911,17907,17905');

$base-dark: #1b1c1d;
$dark-grey: #605e5a;
$base-green: #67ce67;
$aux-orange: #eeb335;
$base-grey: #f9f9f9;
$white: #fff;

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $base-grey;
  font-family: 'Circular Std', sans-serif;
  color: $base-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-left: 24px;
  padding-right: 24px; */
}

/* @media only screen and (min-width: 1224px) {
  body {
    padding-left: 240px;
    padding-right: 240px;
  }
} */

sub {
  font-size: 70%;
}

.wrapper {
  width: 1180px;
  max-width: 90%;
  margin: 0 auto;
}

.hide-mob {
  @media (max-width: 640px) {
    display: none;
  }
}

a {
  svg {
    margin-right: 10px;
  }

  &:hover {
    color: $base-green !important;

    svg path {
      fill: $base-green;
    }

    p {
      border-bottom: 2px solid $base-green !important;
    }


  }
}


::-webkit-scrollbar {
  display: none;
}

.pin-section {
  will-change: opacity;
  position: relative;
  z-index: 1;
}

.coming-soon {
    color: #EEB335;
    background: rgba(238, 179, 53, 0.08);
    padding: 8px 12px;
    border-radius: 36px;
    font-size: 12px;
    text-transform: uppercase;
}


.about__card .card__body figure img {
  border-radius: 0;
}

.about__card .card__body figure video {
  border-radius: 0;
}
