.header {
  padding-top: 50px;

  transform: translateY(-120%);
  opacity: 0;
  will-change: transform, opacity;

  &__logo {
    width: 136px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__links {
    display: flex;
    align-items: center;

    button {
      @media (max-width: 480px) {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 24px;
      @extend %description-18-medium;

      @media (max-width: 480px) {
        margin-right: 0px;
      }

      p {
        border-bottom: 2px solid rgba(0, 0, 0, 0.16);
        padding-bottom: 2px;
      }
    }
  }
}

.wallet-adapter-button-trigger {
  background: $base-dark;
}

// .wallet-adapter-button {
//   @extend %description-18-medium;
//   background: $base-dark;
//   border-radius: 12px;
//   color: #fff;
//   cursor: pointer;
//   font-family: inherit;
//   padding: 12px 16px;

// }
